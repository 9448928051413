import { IsNumber, IsOptional, IsString } from 'class-validator'

export class SaveCsrFormChangeLog {
  @IsNumber()
  formId: number

  @IsNumber()
  userId: number

  @IsString()
  @IsOptional()
  oldValue?: string

  @IsString()
  newValue: string

  @IsString()
  action: 'updated' | 'created' | 'deleted' | 'patched'
}
