import 'reflect-metadata'
import { plainToInstance, plainToClass, Type } from 'class-transformer'
import { ArrayNotEmpty, IsArray, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'

import { SaveTranslation } from './translation'
import { Translation } from './language'

export class ConstantKeyTranslation {
  languageId: number | undefined
  value: string | undefined
  language?: string

  constructor(c?: ConstantKeyTranslation) {
    this.languageId = c?.languageId
    this.value = c?.value
    this.language = c?.language
  }
}

export class ConstantKeyCropTypes {
  id: number | undefined
  name: string | undefined

  constructor(c?: ConstantKeyCropTypes) {
    this.id = c?.id
    this.name = c?.name
  }
}

export class ConstantKeyRegions {
  id: number | undefined
  name: string | undefined

  constructor(c?: ConstantKeyRegions) {
    this.id = c?.id
    this.name = c?.name
  }
}

export class ConstantKeyValue {
  id: number | undefined
  name: string | undefined
  constantName?: string
  cropTypes?: ConstantKeyCropTypes[]
  regions?: ConstantKeyRegions[]
  subType?: string
  item?: string
  priority?: number
  type?: string
  color?: string
  translations: ConstantKeyTranslation[] | undefined

  constructor(c?: ConstantKeyValue) {
    this.id = c?.id
    this.name = c?.name
    this.cropTypes = c?.cropTypes
    this.regions = c?.regions
    this.subType = c?.subType
    this.item = c?.item
    this.priority = c?.priority
    this.type = c?.type
    this.color = c?.color
    this.translations = c?.translations
  }
}

export class ConstantKey {
  [key: string]: ConstantKeyValue
}

export class Constant {
  id: number | undefined
  name: string | undefined
  values: ConstantKey | undefined
  constructor(c?: Constant) {
    this.id = c?.id
    this.name = c?.name
    this.values = c?.values
  }
}

export class ConstantKeyList {
  [constantName: string]: string[]
}

export class ConstantRequest {
  constantId: number | undefined
  constantName: string | undefined
  keyId: number | undefined
  keyName: string | undefined
  keyColor?: string
  keyItem?: string
  keyPriority?: number
  keySubType?: string
  keyType?: string
  regionIds: string | undefined
  regionNames: string | undefined
  croptypeIds: string | undefined
  croptypeNames: string | undefined
  translations: string | undefined
  languageIds: string | undefined
  languageNames: string | undefined

  constructor(c?: ConstantRequest) {
    this.constantId = c?.constantId
    this.constantName = c?.constantName
    this.keyId = c?.keyId
    this.keyName = c?.keyName
    this.keyColor = c?.keyColor
    this.keyItem = c?.keyItem
    this.keyPriority = c?.keyPriority
    this.keySubType = c?.keySubType
    this.regionIds = c?.regionIds
    this.regionNames = c?.regionNames
    this.croptypeIds = c?.croptypeIds
    this.croptypeNames = c?.croptypeNames
    this.translations = c?.translations
    this.languageIds = c?.languageIds
    this.languageNames = c?.languageNames
  }
}

export class ConstantKeyRequest {
  key: string | undefined
  color?: string
  keyItem?: string
  keyPriority?: string
  keySubType?: string
  keyType?: string
  regionIds: string | undefined
  regionNames: string | undefined
  croptypeIds: string | undefined
  croptypeNames: string | undefined
  translations: string | undefined
  languageIds: string | undefined
  languageNames: string | undefined

  constructor(c?: ConstantKeyRequest) {
    this.key = c?.key
    this.color = c?.color
    this.keyItem = c?.keyItem
    this.keyPriority = c?.keyPriority
    this.keySubType = c?.keySubType
    this.keyType = c?.keyType
    this.regionIds = c?.regionIds
    this.regionNames = c?.regionNames
    this.croptypeIds = c?.croptypeIds
    this.croptypeNames = c?.croptypeNames
    this.translations = c?.translations
    this.languageIds = c?.languageIds
    this.languageNames = c?.languageNames
  }
}

export class ConstantDto {
  id: number | undefined
  name: string | undefined
  cropTypes?: string
  regionNames?: string
  subType?: string
  item?: string
  priority?: number
  type?: string
  color?: string

  constructor(c?: ConstantDto) {
    this.id = c?.id
    this.name = c?.name
    this.cropTypes = c?.cropTypes
    this.regionNames = c?.regionNames
    this.subType = c?.subType
    this.item = c?.item
    this.priority = c?.priority
    this.type = c?.type
    this.color = c?.color
  }
}

export class ConstantKeyDto {
  id?: number | undefined
  key: string | undefined
  subType?: string
  item?: string
  priority?: number
  type?: string
  color?: string

  constructor(constantKey?: SaveConstantKey) {
    this.key = constantKey?.name
    this.subType = constantKey?.subType
    this.item = constantKey?.item
    this.priority = constantKey?.priority
    this.type = constantKey?.type
    this.color = constantKey?.color
  }
}

export class SaveConstantKey {
  public static build(raw: any) {
    return plainToInstance(SaveConstantKey, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  constantId: number | undefined

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsArray()
  @IsOptional()
  @IsNumber({}, { each: true })
  cropTypeIds?: number[]

  @IsArray()
  @IsOptional()
  cropTypes?: []

  @IsArray()
  @IsOptional()
  @IsNumber({}, { each: true })
  regionIds?: number[]

  @IsArray()
  @IsOptional()
  regions?: []

  @IsString()
  @IsOptional()
  subType?: string

  @IsString()
  @IsOptional()
  item?: string

  @IsNumber()
  @IsOptional()
  priority?: number

  @IsString()
  @IsOptional()
  type?: string

  @IsString()
  @IsOptional()
  color?: string

  @IsArray()
  @ArrayNotEmpty()
  translations: SaveTranslation[] | undefined

  constructor(saveConstantKey?: SaveConstantKey) {
    this.id = saveConstantKey?.id
    this.constantId = saveConstantKey?.constantId
    this.name = saveConstantKey?.name
    this.cropTypes = saveConstantKey?.cropTypes
    this.cropTypeIds = saveConstantKey?.cropTypeIds
    this.regions = saveConstantKey?.regions
    this.cropTypeIds = saveConstantKey?.cropTypeIds
    this.regionIds = saveConstantKey?.regionIds
    this.subType = saveConstantKey?.subType
    this.item = saveConstantKey?.item
    this.priority = saveConstantKey?.priority
    this.type = saveConstantKey?.type
    this.translations = saveConstantKey?.translations
  }
}

export class SaveConstant {
  public static build(raw: any) {
    return plainToInstance(SaveConstant, raw)
  }

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  constructor(c?: ConstantKeyRegions) {
    this.name = c?.name
  }
}

export class SaveExternalConstant {
  public static build(raw: any) {
    return plainToClass(SaveExternalConstant, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsOptional()
  key?: string

  @IsString()
  @IsOptional()
  name?: string

  @IsString()
  @IsOptional()
  type?: string

  @IsNotEmpty()
  @Type(() => SaveExternalConstantTranslation)
  @ValidateNested()
  translations: SaveExternalConstantTranslation[] = []

  @IsArray()
  @ArrayNotEmpty()
  @IsNumber({}, { each: true })
  regionIds?: number[] = []

  @IsArray()
  @IsNumber({}, { each: true })
  croptypeIds?: number[] = []
}

export class SaveExternalConstantTranslation {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  languageId!: number

  @IsString()
  @IsNotEmpty()
  value!: string
}

export class ExternalConstantTranslation {
  languageId!: number
  value!: string
  language?: string
}

export class ExternalConstantRegion {
  id?: number
}

export class ExternalConstant {
  id?: number
  key?: string
  name?: string
  translations?: ExternalConstantTranslation[]
  regions?: ExternalConstantRegion[]

  constructor(externalConstant?: ExternalConstant) {
    this.id = externalConstant?.id
    this.key = externalConstant?.key
    this.name = externalConstant?.name
    this.translations = externalConstant?.translations ?? []
    this.regions = externalConstant?.regions ?? []
  }
}

export class ConstantValues {
  constantId?: number
  constantName?: string
  keys: ConstantKeyValue[]

  constructor(constantValues?: ConstantValues) {
    this.constantName = constantValues?.constantName
    this.keys = constantValues?.keys ?? []
  }
}

export class ConstantLite {
  id?: number
  code: string
  name?: string
  translation: string
  translations?: Translation[]
  cropTypes?: ConstantKeyCropTypes[]
  regions?: ConstantKeyRegions[]
  subType?: string
  item?: string
  priority?: number
  type?: string
  croptypeId?: number
}

export class Constants {
  // constant name
  [key: string]: Constant
}

export class ConstantsLite {
  // constant name
  [key: string]: ConstantLite[]
}
