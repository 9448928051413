import {
  WebSocketEditNotification,
  WebSocketEditNotificationElement,
  WebSocketEditNotificationStatus,
} from '@agroone/entities'
import { Injectable } from '@angular/core'
import { BehaviorSubject, map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class WebSocketStateService {
  public readonly elementsSubject: BehaviorSubject<WebSocketEditNotification[]> = new BehaviorSubject<
    WebSocketEditNotification[]
  >([])
  public readonly elements$: Observable<WebSocketEditNotification[]> = this.elementsSubject.asObservable()

  setEditingStatus(notification: WebSocketEditNotification): void {
    const currentElements: WebSocketEditNotification[] = this.elementsSubject.getValue()
    const updatedElements: WebSocketEditNotification[] = currentElements.map((element) =>
      element.elementId === notification.elementId && element.elementType === notification.elementType
        ? { ...element, status: notification.status }
        : element
    )

    if (
      !currentElements.some(
        (element) => element.elementId === notification.elementId && element.elementType === notification.elementType
      )
    ) {
      updatedElements.push(notification)
    }

    this.elementsSubject.next(updatedElements)
  }

  isEditing(elementId: string, elementType: WebSocketEditNotificationElement): Observable<WebSocketEditNotification> {
    return this.elements$.pipe(
      map((elements: WebSocketEditNotification[]) => {
        const element: WebSocketEditNotification | undefined = elements.find(
          (element: WebSocketEditNotification) => element.elementId === elementId && element.elementType === elementType
        )
        return element?.status === WebSocketEditNotificationStatus.EDITING ? element : undefined
      })
    )
  }
}
