import { NetworkService } from '@agroone-front/shared'
import {
  HarvestOfferPriorisation,
  IFilters,
  Offer,
  PatchOfferDto,
  RangeFilter,
  SaveOffer,
  UpdateHarvestTeamDto,
} from '@agroone/entities'
import { addDays, isWithinInterval } from '@agropilot/app/core/library/date'
import { parseDateFilter } from '@agropilot/app/core/library/date-utility'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { addSeconds, subSeconds } from 'date-fns'
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { getTimezoneOffset } from 'date-fns-tz'
import { getDateForTimezone, getDateStringForTimezone } from '@agroone/dates'

export class inMemoryFilters {
  forecastHarvestDate?: RangeFilter

  constructor(filter: IFilters) {
    this.forecastHarvestDate =
      filter?.forecastHarvestDate?.start && filter?.forecastHarvestDate?.end ? filter.forecastHarvestDate : null
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  public offers: Offer[]

  public offersWithoutMaturitySubject: BehaviorSubject<Offer[]> = new BehaviorSubject([])
  public offersWithoutMaturity$: Observable<Offer[]> = this.offersWithoutMaturitySubject.asObservable()

  private inMemoryFilter: inMemoryFilters

  private _reloadOffersSubject: Subject<void> = new Subject()

  constructor(private http: HttpClient, private networkService: NetworkService) {}

  emitReloadOffers() {
    this._reloadOffersSubject.next()
  }

  getReloadOffers$() {
    return this._reloadOffersSubject.asObservable()
  }

  public get(id: number): Observable<Offer> {
    return this.http.get<Offer>(`${environment.apiUrl}/offers-v2/${id}`).pipe(
      // Map the received offer to a new Offer instance
      map((offer) => new Offer(offer)),

      catchError((error) => {
        // Try to retrieve the offer from the local cache if available
        const cachedOffer = this.offers?.find((offerItem) => offerItem.id === id)

        return cachedOffer ? of(cachedOffer) : throwError(() => error)
      })
    )
  }

  public getAll(filters?: IFilters, sortBy?: { sortBy: string; sortOrder: string }): Observable<Offer[]> {
    return from(
      this.networkService.getAllFromPaginated<Offer>(
        `${environment.apiUrl}/offers-v2?query=${JSON.stringify(filters)}`,
        {
          params: {
            ...{ pageLength: 1000 },
            ...(sortBy ? { sortBy: sortBy.sortBy, sortOrder: sortBy.sortOrder } : {}),
          },
        }
      )
    ).pipe(
      map((offers) => {
        this.offersWithoutMaturitySubject.next(offers)
        if (offers) {
          if (this.inMemoryFilter?.forecastHarvestDate) {
            return offers.filter((o: Offer) =>
              isWithinInterval(
                o.forecastHarvestDate,
                parseDateFilter(this.inMemoryFilter.forecastHarvestDate.start),
                parseDateFilter(addDays(this.inMemoryFilter.forecastHarvestDate.end, 1))
              )
            )
          }
          return offers
        }
      }),
      tap((offers: Offer[]) => {
        offers.forEach((offer) => {
          offer.startHarvestDate = getDateStringForTimezone(offer.startHarvestDate, 'Europe/Paris')
          offer.endHarvestDate = getDateStringForTimezone(offer.endHarvestDate, 'Europe/Paris')

          offer.lotsV2?.forEach((lot) => {
            lot.harvestingSiteStartTheorical = lot.harvestingSiteStartTheorical
              ? getDateForTimezone(new Date(lot.harvestingSiteStartTheorical), 'Europe/Paris')
              : null

            lot.harvestingSiteStartReal = lot.harvestingSiteStartReal
              ? getDateForTimezone(new Date(lot.harvestingSiteStartReal), 'Europe/Paris')
              : null

            lot.startHarvestDateTheorical = lot.startHarvestDateTheorical
              ? getDateForTimezone(new Date(lot.startHarvestDateTheorical), 'Europe/Paris')
              : null
            lot.startHarvestDateReal = lot.startHarvestDateReal
              ? getDateForTimezone(new Date(lot.startHarvestDateReal), 'Europe/Paris')
              : null
            lot.endHarvestDateTheorical = lot.endHarvestDateTheorical
              ? getDateForTimezone(new Date(lot.endHarvestDateTheorical), 'Europe/Paris')
              : null
            lot.endHarvestDateReal = lot.endHarvestDateReal
              ? getDateForTimezone(new Date(lot.endHarvestDateReal), 'Europe/Paris')
              : null

            lot.truckFieldArrivalTheorical = lot.truckFieldArrivalTheorical
              ? getDateForTimezone(new Date(lot.truckFieldArrivalTheorical), 'Europe/Paris')
              : null
            lot.truckFieldArrivalReal = lot.truckFieldArrivalReal
              ? getDateForTimezone(new Date(lot.truckFieldArrivalReal), 'Europe/Paris')
              : null

            lot.truckFieldLeaveTheorical = lot.truckFieldLeaveTheorical
              ? getDateForTimezone(new Date(lot.truckFieldLeaveTheorical), 'Europe/Paris')
              : null
            lot.truckFieldLeaveReal = lot.truckFieldLeaveReal
              ? getDateForTimezone(new Date(lot.truckFieldLeaveReal), 'Europe/Paris')
              : null

            lot.truckFactoryArrivalTheorical = lot.truckFactoryArrivalTheorical
              ? getDateForTimezone(new Date(lot.truckFactoryArrivalTheorical), 'Europe/Paris')
              : null
            lot.truckFactoryArrivalReal = lot.truckFactoryArrivalReal
              ? getDateForTimezone(new Date(lot.truckFactoryArrivalReal), 'Europe/Paris')
              : null

            lot.truckFactoryLeaveTheorical = lot.truckFactoryLeaveTheorical
              ? getDateForTimezone(new Date(lot.truckFactoryLeaveTheorical), 'Europe/Paris')
              : null
            lot.truckFactoryLeaveReal = lot.truckFactoryLeaveReal
              ? getDateForTimezone(new Date(lot.truckFactoryLeaveReal), 'Europe/Paris')
              : null

            lot.breaks.forEach((br) => {
              br.startDate = getDateStringForTimezone(br.startDate, 'Europe/Paris')
              br.endDate = getDateStringForTimezone(br.endDate, 'Europe/Paris')
            })
          })
        })

        return offers
      })
    )
  }

  public getPriorisation(filter?: string): Observable<HarvestOfferPriorisation[]> {
    return this.http.get<HarvestOfferPriorisation[]>(
      `${environment.apiUrl}/offers-v2/priorisation${filter ? filter : ''}`
    )
  }

  public adaptOfferPlanification(offerId: number, offerData: PatchOfferDto): Observable<Offer> {
    if (offerData.startHarvestDate) {
      offerData.startHarvestDate = addSeconds(
        new Date(offerData.startHarvestDate),
        getTimezoneOffset('Europe/Paris', offerData.startHarvestDate) / 1000
      )
    }

    return this.http.patch<Offer>(`${environment.apiUrl}/offers-v2/${offerId}/adapt-planification`, offerData)
  }

  public updateHarvestStartDate(offerId: number, replacingOfferId: number, yesterdayDate: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.apiUrl}/offers-v2/${offerId}/harvest-start-date`, {
      replacingOfferId,
      yesterdayDate,
    })
  }

  public updateHarvestTeam(offerId: number, updateHarvestTeamDto: UpdateHarvestTeamDto): Observable<Offer> {
    updateHarvestTeamDto.yesterdayDate = addSeconds(
      new Date(updateHarvestTeamDto.yesterdayDate).setHours(0, 0, 0, 0),
      getTimezoneOffset('Europe/Paris', new Date(updateHarvestTeamDto.yesterdayDate).setHours(0, 0, 0, 0)) / 1000
    ).toISOString()
    return this.http.patch<Offer>(`${environment.apiUrl}/offers-v2/${offerId}/harvest-team`, updateHarvestTeamDto)
  }

  public unplanifyOffer(offerId: number, yesterdayDate: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.apiUrl}/offers-v2/${offerId}/unplanify`, { yesterdayDate })
  }

  public post(offer: SaveOffer): Observable<Offer> {
    return this.http.post<Offer>(`${environment.apiUrl}/offers-v2`, offer)
  }

  public update(offer: SaveOffer): Observable<Offer> {
    return this.http.put<Offer>(`${environment.apiUrl}/offers-v2`, offer)
  }

  public openOrCloseOffer(offerId: number): Observable<Offer> {
    return this.http.post<Offer>(`${environment.apiUrl}/offers-v2/${offerId}/open-close`, {})
  }

  public patch(offerId: number, offer: PatchOfferDto): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.apiUrl}/offers-v2/${offerId}`, offer)
  }

  public updateDemandSampleDates(dates: string[], offerId: number): Observable<string[]> {
    return this.http.put<string[]>(`${environment.apiUrl}/offers-v2/demand-samples/${offerId}`, { dates })
  }

  public delete(id: number): Observable<Offer> {
    return this.http.delete<Offer>(`${environment.apiUrl}/offers-v2/${id}`)
  }

  public resetPriorisation(body: { date?: string; id?: string }) {
    return this.http.patch(`${environment.apiUrl}/offers-v2/reset`, body)
  }
}
