import { Type } from 'class-transformer'
import { IsJSON, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'

export class SaveCsrFormResponse {
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  id?: number

  @IsNumber()
  @Type(() => Number)
  constantId: number

  @IsOptional()
  @IsString()
  @Type(() => String)
  value: string | null

  @IsString()
  type: 'string' | 'number' | 'boolean' | 'json'

  @IsString()
  @IsOptional()
  unit?: string

  @IsOptional()
  @IsJSON()
  @ValidateIf((o) => o.metadata)
  metadata?: string
}
