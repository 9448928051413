import { IsInt, IsNumber, IsPositive } from 'class-validator'

export class AddLotDto {
  @IsNumber()
  @IsPositive()
  tonnage: number

  @IsNumber()
  @IsInt()
  @IsPositive()
  offerId: number
}
