export enum WebSocketEditNotificationStatus {
  EDITING = 'editing',
  AVAILABLE = 'available',
  GET_EDITING_ELEMENTS = 'get-editing-elements',
}

export enum WebSocketEditNotificationElement {
  PLANIFICATION_OFFER = 'planification-offer',
  LOT = 'lot',
}

export class WebSocketEditNotification {
  elementId!: string // Unique identifier of the HTML element
  elementType!: WebSocketEditNotificationElement
  userId?: string
  userDetails?: {
    firstName: string
    lastName: string
    email: string
  }
  status!: WebSocketEditNotificationStatus

  constructor(webSocketEditNotification: WebSocketEditNotification) {
    this.elementId = webSocketEditNotification.elementId
    this.elementType = webSocketEditNotification.elementType
    this.userId = webSocketEditNotification.userId
    this.status = webSocketEditNotification.status
  }
}
