import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { AoTab } from './ao-tab.interface'

@Component({
  selector: 'ao-tabs',
  templateUrl: './ao-tabs.component.html',
  standalone: true,
  imports: [TranslateModule, NgFor, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AoTabsComponent implements OnInit {
  @Input() size: 'md' | 'lg' = 'md'
  @Input() style: 'lifted' | 'boxed' | 'bordered' = 'boxed'
  @Input() isTakingFullWidth: boolean = false

  @Input() tabs: AoTab[] = []
  @Input() selectedTab: any

  @Output() selectedTabChange: EventEmitter<any> = new EventEmitter<any>()

  @HostBinding('class') class: string = 'flex justify-center'

  ngOnInit(): void {
    if (this.isTakingFullWidth) {
      this.class = ''
    }

    if (!this.selectedTab && this.tabs && this.tabs.length > 0) {
      this.selectedTab = this.tabs[0].value
      this.selectedTabChange.emit(this.selectedTab)
    }
  }

  selectTab(tab: AoTab): void {
    if (this.selectedTab !== tab.value) {
      if (!tab.isExternalLink) {
        this.selectedTab = tab.value
      }
      this.selectedTabChange.emit(tab.value)
    }
  }
}
