<span
  class="loading"
  [class.loading-spinner]="animation == 'spinner'"
  [class.loading-dots]="animation == 'dots'"
  [class.loading-ring]="animation == 'ring'"
  [class.loading-ball]="animation == 'ball'"
  [class.loading-bars]="animation == 'bars'"
  [class.loading-infinity]="animation == 'infinity'"
  [class.loading-xs]="size == 'xs'"
  [class.loading-sm]="size == 'sm'"
  [class.loading-md]="size == 'md'"
  [class.loading-lg]="size == 'lg'"
  [class.text-primary]="color == 'primary'"
  [class.text-secondary]="color == 'secondary'"
  [class.text-accent]="color == 'accent'"
  [class.text-neutral]="color == 'neutral'"
  [class.text-info]="color == 'info'"
  [class.text-success]="color == 'success'"
  [class.text-warning]="color == 'warning'"
  [class.text-error]="color == 'error'"
></span>
