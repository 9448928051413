import { NgModule } from '@angular/core'
import { AoLoadingBackdropComponent } from './ao-loading-backdrop.component'
import { AoLoadingComponent } from './ao-loading.component'
import { AoLoadingContainerComponent } from './ao-loading-container.component'

@NgModule({
  imports: [],
  declarations: [AoLoadingBackdropComponent, AoLoadingContainerComponent, AoLoadingComponent],
  providers: [],
  exports: [AoLoadingBackdropComponent, AoLoadingContainerComponent, AoLoadingComponent],
})
export class AoLoadingModule {}
