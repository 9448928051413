import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { WebSocketEditNotification, WebSocketEditNotificationElement } from '@agroone/entities'
import { WebSocketStateService } from '../services'
import { combineLatest, forkJoin, map, Observable } from 'rxjs'

@Directive({
  selector: '[wsEditing]',
  standalone: true,
})
@UntilDestroy()
export class WebSocketEditingDirective implements OnInit {
  @Input() wsEditing:
    | { elementId: number; elementType: WebSocketEditNotificationElement; classes: string; offerId?: number }
    | undefined

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private webSocketStateService: WebSocketStateService
  ) {}

  ngOnInit(): void {
    const obs: Observable<WebSocketEditNotification>[] = []
    if (this.wsEditing?.elementId) {
      obs.push(this.webSocketStateService.isEditing(this.wsEditing.elementId.toString(), this.wsEditing.elementType))
    }
    if (this.wsEditing?.offerId) {
      obs.push(
        this.webSocketStateService.isEditing(
          this.wsEditing.offerId.toString(),
          WebSocketEditNotificationElement.PLANIFICATION_OFFER
        )
      )
    }
    combineLatest(obs)
      .pipe(
        untilDestroyed(this),
        map(([a, b]) => a || b)
      )
      .subscribe((isEditing) => {
        this.updateStyles(!!isEditing)
      })
  }

  private updateStyles(isEditing: boolean | undefined): void {
    if (isEditing) {
      this.wsEditing.classes.split(' ').forEach((className) => {
        this.renderer.addClass(this.el.nativeElement, className)
      })
    } else {
      this.wsEditing.classes.split(' ').forEach((className) => {
        this.renderer.removeClass(this.el.nativeElement, className)
      })
    }
  }
}
