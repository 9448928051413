import { Component, OnInit } from '@angular/core'
import { AuthService } from '../services'
import { AoLoadingModule } from '../components/ao-loading/ao-loading.module'
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [AoLoadingModule],
  template: '<ao-loading />',
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.redirectToLogin()
  }
}
