import { DateFormats, DateFormatV2 } from '../enums'
import * as date_fns from 'date-fns'
import { addSeconds, subSeconds } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

export const formatDateV2 = (date: Date, dateFormat: DateFormatV2): string => {
  if (!date) {
    return null
  }
  return date_fns.format(date, dateFormat)
}

// This method must be used before storing a date in the database so it's converted to UTC before being stored
export const setDateStringToTimezone = (dateString: string, timezone: string): string =>
  addSeconds(new Date(dateString), getTimezoneOffset(timezone, new Date(dateString)) / 1000).toISOString()

// This method must be used when receiving data from the database so it's converted to the local timezone
export const getDateStringForTimezone = (dateString: string, timezone: string): string =>
  subSeconds(new Date(dateString), getTimezoneOffset(timezone, new Date(dateString)) / 1000).toISOString()

// This method must be used when receiving data from the database so it's converted to the local timezone
export const getDateForTimezone = (date: Date, timezone: string): Date =>
  subSeconds(date, getTimezoneOffset(timezone, date) / 1000)
/**
 * Formats a given date based on the specified user format.
 *
 * @param date - The date that needs to be formatted, the date should be DateFormatV2
 * @param userFormat - The desired output format, see DateFormats enum
 *
 *
 * @returns {string} - The date formatted according to the specified format.
 *
 */
export const formatDateForUser = (date: Date, userFormat: DateFormats): string => date_fns.format(date, userFormat)
