import { IsInt, IsOptional, IsPositive, IsString } from 'class-validator'

export class UpdateHarvestTeamDto {
  @IsString()
  yesterdayDate!: string

  @IsInt()
  @IsOptional()
  previousOfferId?: number

  @IsInt()
  @IsPositive()
  @IsOptional()
  harvestTeamId?: number
}
