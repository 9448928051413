import { format } from 'date-fns'
import 'reflect-metadata'

import { plainToInstance } from 'class-transformer'
import { IsArray, IsNotEmpty, IsString } from 'class-validator'

export interface IAgreoRealTime {
  CODE_PARCELLE: string
  APPORT: string
  CPT_RECEPTION: string
  ESPECE: string
  EXPLOITATION: string
  CHANTIER: string
  USINE: string
  QUANTITE: string
  QUALITE: string
  DD_RECOLTE: string
  ARRIVEE_CHAMP: string
  DEPART_CHAMP: string
  DATE_RECEPTION: string
  DEPART_USINE: string
  POIDS_NET: string
  CODE_RECEPTION_EXT: string
}

export class AgreoRealTime {
  plotCode: string = null
  job: string = null
  count: string = null
  species: string = null
  exploitation: string = null
  site: string = null
  factory: string = null
  stock_key: string = null
  quantity: number = null
  quality: string = null
  startWork: string = null
  checkInSite: string = null
  checkOutSite: string = null
  checkInFactory: string = null
  checkOutFactory: string = null
  truckWeightLeave: string = null
  lotNumber: string = null

  constructor(config = {}) {
    const keysMap: IAgreoRealTime = {
      CODE_PARCELLE: 'plotCode',
      APPORT: 'job',
      CPT_RECEPTION: 'count',
      ESPECE: 'species',
      EXPLOITATION: 'exploitation',
      CHANTIER: 'site',
      USINE: 'factory',
      QUANTITE: 'quantity',
      QUALITE: 'quality',
      DD_RECOLTE: 'startWork',
      ARRIVEE_CHAMP: 'checkInSite',
      DEPART_CHAMP: 'checkOutSite',
      DATE_RECEPTION: 'checkInFactory',
      DEPART_USINE: 'checkOutFactory',
      POIDS_NET: 'truckWeightLeave',
      CODE_RECEPTION_EXT: 'lotNumber',
    }

    for (const prop of Object.keys(config)) {
      if (config[prop] instanceof Date) {
        config[prop] = format(config[prop], 'yyyy-MM-dd HH:mm:ss')
      }
      if (prop === 'POIDS_NET') {
        config[prop] = parseFloat(config[prop])
      }
      const newKey = keysMap[prop]
      this[newKey] = config[prop]
    }
  }
}

export class SaveAgreoRealTime {
  public static build(raw: any) {
    return plainToInstance(SaveAgreoRealTime, raw)
  }

  @IsArray()
  @IsNotEmpty()
  @IsString({ each: true })
  plot: string[]

  @IsString()
  @IsNotEmpty()
  cropType: string
}
