import { IsBoolean, IsInt, IsNotEmpty, IsNumber, IsPositive, IsString, Min, ValidateIf } from 'class-validator'

import { IsOptional } from 'class-validator'

export class PatchLotV2Dto {
  @IsNumber()
  @IsPositive()
  @IsOptional()
  truckId?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  quantity?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  cadency?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  truckSpeed?: number | null

  @IsNumber()
  @IsPositive()
  @IsOptional()
  stockId?: number | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  startHarvestDateReal?: string | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  endHarvestDateReal?: string | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  truckFieldArrivalReal?: string | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  truckFieldLeaveReal?: string | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  truckFactoryArrivalReal?: string | null

  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  truckFactoryLeaveReal?: string | null

  // In french "Début de la missions"
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  harvestingSiteStartReal?: string | null

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  factoryWaitingTime: number

  @IsNumber()
  @IsPositive()
  @IsOptional()
  intermediateWeighing?: number | null

  @IsString()
  @IsOptional()
  agreoLotId?: string | null

  @IsBoolean()
  @IsOptional()
  ignoreAgreoTruckArrivalReal?: boolean | null

  @IsBoolean()
  @IsOptional()
  ignoreAgreoTruckLeaveReal?: boolean | null

  @IsBoolean()
  @IsOptional()
  ignoreAgreoTruckLeaveWeight?: boolean | null
}
