import { Type } from 'class-transformer'
import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { SaveCsrFormResponse } from './save-csr-form-response'

export class SaveCsrForm {
  @IsNumber()
  @Type(() => Number)
  campaignId: number

  @IsString()
  themeName: string

  @IsOptional()
  @IsString()
  note?: string

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SaveCsrFormResponse)
  responses: SaveCsrFormResponse[]
}
