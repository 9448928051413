import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { DialogModule } from 'primeng/dialog'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  standalone: true,
  imports: [DialogModule, TranslateModule, CommonModule],
})
export class ConfirmDialogComponent {
  public title?: string
  public message: string
  public confirmButtonLabel?: string
  public cancelButtonLabel?: string
  public displayCancelButton: boolean = true
  public style?: string
  public isLoading: boolean = false

  constructor(private config: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {
    this.title = config.data?.title
    this.message = config.data?.message
    this.confirmButtonLabel = config.data?.confirmButtonLabel
    this.cancelButtonLabel = config.data?.cancelButtonLabel
    this.displayCancelButton = config.data?.displayCancelButton
    this.style = config.data?.style
  }

  onConfirm(): void {
    if (this.isLoading) {
      return
    }
    this.isLoading = true
    this.dialogRef.close(true)
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }
}
