<div class="modal-box">
  <div class="modal-title">
    <h6 *ngIf="title" class="font-bold">{{ title }}</h6>
  </div>
  <div class="modal-content">
    <p>{{ message }}</p>
  </div>
  <div class="modal-action">
    <button *ngIf="displayCancelButton" [class.disabled]="isLoading" class="btn" (click)="onCancel()">
      {{ cancelButtonLabel || ('NO' | translate) }}
    </button>
    <button [class.disabled]="isLoading" class="btn btn-primary" (click)="onConfirm()">
      <span *ngIf="isLoading" class="loading loading-spinner"></span>
      {{ confirmButtonLabel || ('YES' | translate) }}
    </button>
  </div>
</div>
