import { Component, Input } from '@angular/core'

export type AoLoadingAnimation = 'spinner' | 'dots' | 'ring' | 'ball' | 'bars' | 'infinity'

export type AoLoadingSize = 'xs' | 'sm' | 'md' | 'lg'

export type AoLoadingColor = 'primary' | 'secondary' | 'accent' | 'neutral' | 'info' | 'success' | 'warning' | 'error'

@Component({
  selector: 'ao-loading',
  templateUrl: './ao-loading.component.html',
  host: {
    class: 'flex',
  },
})
export class AoLoadingComponent {
  @Input()
  animation: AoLoadingAnimation

  @Input()
  size: AoLoadingSize

  @Input()
  color: AoLoadingColor
}
