import * as date_fns from 'date-fns'
import { BreakDto } from './break.dto'
import { FactoryRealTime } from './factory-realtime'
import { Stock } from './stock'
import { Truck } from './truck'

export class LotV2 {
  id: number
  startHarvestDateTheorical: Date
  startHarvestDateReal: Date
  endHarvestDateTheorical: Date
  endHarvestDateReal: Date
  truckFieldArrivalTheorical: Date
  truckFieldArrivalReal: Date
  truckFieldLeaveTheorical: Date
  truckFieldLeaveReal: Date
  truckFactoryArrivalTheorical: Date
  truckFactoryArrivalReal: Date
  truckFactoryLeaveTheorical: Date
  truckFactoryLeaveReal: Date
  harvestingSiteStartTheorical: Date
  harvestingSiteStartReal: Date
  truck: Truck
  truckSpeed?: number
  stock?: Stock
  notes: string
  cadency: number
  quantity: number
  factoryWaitingTime: number
  intermediateWeighing: number
  breaks: BreakDto[]
  mixLot?: { id: number }
  agreoLotId?: string
  ignoreAgreoTruckArrivalReal?: boolean
  ignoreAgreoTruckLeaveReal?: boolean
  ignoreAgreoTruckLeaveWeight?: boolean
}

export class Lot {
  id: number
  quantity: number
  offerId: number
  siteId: number
  dateBegin: Date
  dateEnd: Date
  startWork: string
  checkInSite: string
  checkOutSite: string
  checkInFactory: string
  checkOutFactory: string
  dateBeginBreak: Date
  dateEndBreak: Date
  deltaStartWork: number
  deltaCheckInSite: number
  deltaCheckOutSite: number
  deltaCheckInFactory: number
  deltaCheckOutFactory: number
  estimationStartWork: string
  estimationCheckInSite: string
  estimationCheckOutSite: string
  estimationCheckInFactory: string
  estimationCheckOutFactory: string
  cadenceTh: number
  quality: string
  truckId: number
  truckSpeed: number
  stockId: number
  timeInSite: number
  modifiedBy: string
  lastUpdate: string | Date
  mixLotId: number
  factory_realtime: FactoryRealTime
  timeWaitFactory: number
  region: string
  regionId: number
  cropType: string
  note: string
  planificationId: number

  constructor(lot?: Lot) {
    if (lot) {
      if (lot.id != null) {
        this.id = lot.id
      }
      if (lot.quantity != null) {
        this.quantity = lot.quantity
      }
      if (lot.offerId != null) {
        this.offerId = lot.offerId
      }
      if (lot.siteId != null) {
        this.siteId = lot.siteId
      }
      if (lot.dateBegin != null) {
        this.dateBegin = lot.dateBegin
      }
      if (lot.dateEnd != null) {
        this.dateEnd = lot.dateEnd
      }
      if (lot.startWork != null) {
        this.startWork = lot.startWork
      }
      if (lot.checkInSite != null) {
        this.checkInSite = lot.checkInSite
      }
      if (lot.checkOutSite != null) {
        this.checkOutSite = lot.checkOutSite
      }
      if (lot.checkInFactory != null) {
        this.checkInFactory = lot.checkInFactory
      }
      if (lot.checkOutFactory != null) {
        this.checkOutFactory = lot.checkOutFactory
      }
      this.dateBeginBreak = lot.dateBeginBreak ?? null
      this.dateEndBreak = lot.dateEndBreak ?? null
      if (lot.deltaStartWork != null) {
        this.deltaStartWork = lot.deltaStartWork
      }
      if (lot.deltaCheckInSite != null) {
        this.deltaCheckInSite = lot.deltaCheckInSite
      }
      if (lot.deltaCheckOutSite != null) {
        this.deltaCheckOutSite = lot.deltaCheckOutSite
      }
      if (lot.deltaCheckInFactory != null) {
        this.deltaCheckInFactory = lot.deltaCheckInFactory
      }
      if (lot.deltaCheckOutFactory != null) {
        this.deltaCheckOutFactory = lot.deltaCheckOutFactory
      }
      if (lot.estimationStartWork != null) {
        this.estimationStartWork = lot.estimationStartWork
      }
      if (lot.estimationCheckInSite != null) {
        this.estimationCheckInSite = lot.estimationCheckInSite
      }
      if (lot.estimationCheckOutSite != null) {
        this.estimationCheckOutSite = lot.estimationCheckOutSite
      }
      if (lot.estimationCheckInFactory != null) {
        this.estimationCheckInFactory = lot.estimationCheckInFactory
      }
      if (lot.estimationCheckOutFactory != null) {
        this.estimationCheckOutFactory = lot.estimationCheckOutFactory
      }
      if (lot.cadenceTh != null) {
        this.cadenceTh = lot.cadenceTh
      }
      if (lot.quality != null) {
        this.quality = lot.quality
      }
      if (lot.truckId !== undefined) {
        this.truckId = lot.truckId
      }
      if (lot.dateBeginBreak != null) {
        this.dateBeginBreak = lot.dateBeginBreak
      }
      if (lot.dateEndBreak != null) {
        this.dateEndBreak = lot.dateEndBreak
      }
      if (lot.stockId != null) {
        this.stockId = lot.stockId
      }
      if (lot.truckSpeed != null) {
        this.truckSpeed = lot.truckSpeed
      }
      if (lot.timeInSite != null) {
        this.timeInSite = lot.timeInSite
      }
      if (lot.modifiedBy != null) {
        this.modifiedBy = lot.modifiedBy
      }
      if (lot.mixLotId != null) {
        this.mixLotId = lot.mixLotId
      }
      if (lot.factory_realtime != null) {
        this.factory_realtime = new FactoryRealTime()
      }
      if (lot.timeWaitFactory != null) {
        this.timeWaitFactory = lot.timeWaitFactory
      }
      if (lot.region != null) {
        this.region = lot.region
      }
      if (lot.regionId != null) {
        this.regionId = lot.regionId
      }
      if (lot.cropType != null) {
        this.cropType = lot.cropType
      }
      if (lot.note != null) {
        this.note = lot.note
      }
      if (lot.planificationId != null) {
        this.planificationId = lot.planificationId
      }
    }
    this.lastUpdate = date_fns.format(new Date(), 'yyyy-MM-dd HH:mm:ss')
  }
}
