import { Equals, IsBoolean, IsOptional, IsString } from 'class-validator'

export class GrowerAgroCharterSignatureForm {
  @IsBoolean()
  @Equals(true)
  acceptTerms: boolean

  @IsString()
  growerSignature: string

  @IsString()
  @IsOptional()
  bndSignature?: string
}
